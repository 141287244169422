import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import EventsListing from "./Pages/EventsListing";
import "./App.css";
import Subscription from "./Pages/Subscription";
import StripeSucess from "./Pages/StripeSucess";
import StripeCancel from "./Pages/StripeCancel";
import VerifyQR from "./Pages/VerifyQR";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/events" element={<EventsListing />} />
          <Route path="/verifyQR" element={<VerifyQR />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/stripe/Success" element={<StripeSucess />} />
          <Route path="/stripe/Cancel" element={<StripeCancel />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
