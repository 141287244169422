import React, { useEffect } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";

const StripeCancel = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
  }, []);
  return (
    <div>
      <div
        style={{
          fontSize: "30px",
          color: "green",
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        Thank You
      </div>
      <div>
        <div>
          <CheckCircleOutlined
            style={{
              fontSize: "70px",
              color: "green",
            }}
          />
        </div>
      </div>
      <div
        style={{
          fontSize: "20px",
          color: "green",
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        You have been Successfully Un-Subscribed from our Monthly Plan
      </div>
      <div
        style={{
          fontSize: "14px",
          color: "green",
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        You will be redirected to the home page in 5 seconds
      </div>
    </div>
  );
};

export default StripeCancel;
