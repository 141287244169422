import React, { useState } from "react";
import QrReader from "modern-react-qr-reader";
import { API_URL } from "../config";
import { LoadingOutlined, RedoOutlined } from "@ant-design/icons";
const QRScanner = () => {
  const [Result, setResult] = useState();
  const [ShowResult, setShowResult] = useState(false);
  const [processingQR, setProcessingQR] = useState(false);
  const handleScan = async (data) => {
    if (data && !processingQR) {
      setProcessingQR(true);
      setTimeout(() => {
        checkSubscription(data);
      }, 4000);
    }
  };
  const checkSubscription = async (data) => {
    try {
      await fetch(`${API_URL}/api/reserve/subscribe/check-subscription`, {
        method: "POST",
        headers: { "Content-Type": "Application/JSON" },
        body: JSON.stringify({
          qrCode: data,
        }),
      }).then((response) => {
        response.json().then((data) => {
          if (response?.status === 200 && data?.subscriptions?.length > 0) {
            setResult("Verified Subscription");
            setShowResult(true);
            setProcessingQR(false);
          } else {
            setResult("Subscription Not Found");
            setShowResult(true);
            setProcessingQR(false);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section>
      <section
        className="
    flex justify-center items-center h-screen  bg-gray-100 flex-col
    "
      >
        <div
          className="
    mb-4 text-gray-600 text-xl font-semibold
      "
        >
          Membership Verification
        </div>
        <div
          className="
    h-[400px] w-[100%] md:w-[400px] border-2 border-gray-300 rounded-md p-2
    "
        >
          {!ShowResult && !processingQR && (
            <QrReader
              onError={(error) => {
                console.error(error);
              }}
              onScan={(result, error) => {
                if (!!result) {
                  handleScan(result);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              facingMode="environment"
              style={{ width: "100%" }}
            />
          )}
          {ShowResult && (
            <div
              style={{
                fontSize: "30px",
                textAlign: "center",
                fontWeight: "bold",
                color: "#db7617",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div>{Result}</div>
            </div>
          )}
          {!ShowResult && processingQR && (
            <div
              style={{
                fontSize: "30px",
                textAlign: "center",
                fontWeight: "bold",
                color: "#db7617",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              Checking... <LoadingOutlined />
            </div>
          )}
        </div>
        <div
          className="
    mt-4 text-gray-600 text-lg font-semibold
      "
        >
          Scan QR to Verify Subscription{" "}
        </div>
        <div
          className="mt-2 cursor-pointer"
          style={{
            border: "1px solid gray",
            padding: "5px 15px",
            borderRadius: "5px",
          }}
        >
          Scan again{" "}
          <RedoOutlined
            onClick={() => {
              setResult();
              setShowResult(false);
              setProcessingQR(false);
            }}
          />
        </div>
      </section>
    </section>
  );
};

export default QRScanner;
