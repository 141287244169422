import React, { useEffect } from "react";

const Index = () => {
  //Start Widget code

  useEffect(() => {
    const existingIframe = document.getElementById("juznow-web-widget");
    if (existingIframe) {
      return;
    }
    const script = document.createElement("script");
    script.src =
      "https://com-juznow-test.s3.ap-southeast-2.amazonaws.com/sys/jnWebWidgetLdr.js";
    script.defer = true;
    script.ver = "0.7.3";
    script.type = "text/javascript";
    script.setAttribute(
      "token",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ3ZWJzaXRlSWQiOiI2NjI5ZWU1MDhiYjcwYWZlNjgwZTZhMGYiLCJpYXQiOjE3MTQwMjQwMTd9.s4Ic9BkWNhiEN_n_KrnqxXXwcz2iAKWtmcabrLorKp0"
    );
    script.setAttribute("height", "95vh");
    script.setAttribute("width", "100vw");
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
      const e = document.getElementById("juznow-web-widget");
      if (e) {
        document.body.removeChild(e);
      }
    };
  }, []);

  //End Widget code

  return <div></div>;
};

export default Index;
