import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  API_URL,
  STRIPE_PUB_KEY,
  PRODUCT_KEY,
  SUBSCRIBED_SERVICE,
} from "../config";
import { Input, Button } from "antd";

export default function App() {
  let baseUrl = window.location.origin;
  const stripePromise = loadStripe(STRIPE_PUB_KEY);
  const [email, setemail] = useState(null);
  const handleSubscription = async () => {
    if (email) {
      const response = await fetch(
        `${API_URL}/api/reserve/subscribe/stripe-session-subscription`,
        {
          method: "POST",
          headers: { "Content-Type": "Application/JSON" },
          body: JSON.stringify({
            price: PRODUCT_KEY,
            email,
            baseUrl,
            subscribedService: SUBSCRIBED_SERVICE,
          }),
        }
      );

      if (response.status === 409) {
        const data = await response.json();
        if (data && data.redirectUrl) {
          window.location.href = data.redirectUrl; // redirect to billing portal if user is already subscribed
        }
      } else {
        const session = await response.json();
        stripePromise.then((stripe) => {
          stripe
            .redirectToCheckout({ sessionId: session.id })
            .then(function (result) {});
        });
      }
    }
  };
  return (
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          margin: "30px",
          borderWidth: "9px 9px 9px 9px",
          borderStyle: "solid",
          borderColor: "#db7617",
          height: "330px",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
            fontWeight: "bold",
            color: "#db7617",
          }}
        >
          MONTHLY SUBSCRIPTION
        </div>
        <div className="mt-8">
          {" "}
          <Input
            size="large"
            type="text"
            placeholder="Enter Email"
            onChange={(e) => {
              setemail(e.target.value);
            }}
          />
        </div>
        <div className="mt-8">
          <Button
            className="bg-[#db7617] text-[white] hover:!bg-[#36393B] hover:!text-[white] h-[50px] w-[200px] text-xl ont-[Raleway]"
            onClick={() => handleSubscription()}
          >
            Subscribe Now
          </Button>
        </div>
      </div>
      <div
        style={{
          marginTop: "5px",
          fontSize: "20px",
          color: "gray",
          textAlign: "center",
          fontFamily: "Raleway",
        }}
      >
        Sydney.so subscription is managed by <span>Juznow.com</span> <br /> An
        account will be created on <span>Juznow.com</span>,<br /> where you can
        manage your subscription (view/cancel etc).
      </div>
    </section>
  );
}
